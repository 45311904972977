import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../src/image/logo.png'; // Adjust the path as necessary
import './Navbar.css';

const CustomNavbar = () => {
  return (
    <>
      <div className="navbar-container">
      
        <Navbar expand="lg" bg="body-tertiary" className="custom-navbar">
          <div className="navbar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" /> {/* Logo Image */}
            </Link>
          </div>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse className="justify-content-end" id="navbarSupportedContent">
            <Nav className="ml-auto mb-2 mb-lg-0">
              <Nav.Item>
                <Nav.Link as={Link} to="/" activeClassName="menu_active">
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/service" activeClassName="menu_active">
                  Services
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/about" activeClassName="menu_active">
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/contact" activeClassName="menu_active">
                  Contact
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/Download" activeClassName="menu_active">
                  Download
                </Nav.Link>
              </Nav.Item>
              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default CustomNavbar;
