import React from "react";
import { Nav, Navbar } from "react-bootstrap"; // Import Nav and Navbar if you need them
import playstore from '../src/image/playstore.png';
import appstore from '../src/image/appstore.png';
import mobilemockup from '../src/image/mobilemockup.png';
import './Download.css';

const TermsConditions = () => {
  return (
    <>
      <div
        style={{
          padding: '50px',
          maxWidth: '1200px',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333',
          textAlign: 'justify' // Justified text alignment
        }}
      >
        <h2 style={{ color: '#E62188' }}>Cancellation and Refund Policy</h2>
        <p><strong>Effective Date: 26/09/2024</strong></p>
        <p>This Cancellation and Refund Policy (“Policy”) outlines the terms and conditions governing cancellations and refunds for services provided through the website (<a href="https://homelyfservices.com/">https://homelyfservices.com/</a>) and mobile application (‘HomeLyf Services’) (collectively, the “Platform”) operated by QDTAS Technologies LLP (“HomeLyf Services,” “we,” “us,” or “our”). By using the Platform, you agree to this Policy. If you do not agree with any part of this Policy, please do not use the Platform.</p>
        
        <h3>1. General Terms</h3>
        <p>HomeLyf Services is committed to ensuring a fair and transparent process for cancellations and refunds. This Policy details the conditions under which cancellations and refunds may be requested and processed for both Users and Service Providers. Please note that platform fees are nonrefundable in any case.</p>

        <h3>2. Cancellation Policy</h3>
        <p><strong>(a) Cancellation by Users:</strong></p>
        <ul>
          <li><strong>Service Appointments:</strong></li>
          <ul>
            <li><strong>Advance Cancellation:</strong> Users may cancel service appointments without any charges if the cancellation is made more than 3 hours before the scheduled appointment time.</li>
            <li><strong>Late Cancellation:</strong>
              <ul>
                <li>If a User cancels within 3 hours of the scheduled appointment time, a cancellation fee of ₹200 may be applicable if the User is at fault.</li>
                <li>If the cancellation occurs 1 hour after the scheduled booking time due to vendor delay, the User will receive a full refund, and the Vendor will receive one strike.</li>
              </ul>
            </li>
            <li><strong>No-Show:</strong> If a User fails to be present for the appointment without prior cancellation, a no-show fee, up to the full amount of the service fee, may be charged.</li>
            <li><strong>Vendor Not Assigned:</strong> Users can reschedule or cancel for free if no vendor is assigned.</li>
          </ul>
          <li><strong>Subscriptions and Packages:</strong></li>
          <ul>
            <li><strong>Cancellation Process:</strong> Users may cancel subscriptions or service packages by contacting our customer support team. Specific terms and conditions, including cancellation fees and refund eligibility, will be detailed in the subscription or package agreement at the time of purchase.</li>
            <li><strong>Refunds:</strong> Refunds for partially used or expired subscriptions or packages are generally not provided unless otherwise specified in the agreement.</li>
          </ul>
        </ul>

        <p><strong>(b) Cancellation by Service Providers:</strong></p>
        <ul>
          <li><strong>Service Appointments:</strong></li>
          <ul>
            <li><strong>Advance Cancellation:</strong> If a Service Provider cancels more than 3 hours before the appointment, Users will be notified and offered to reschedule or cancel without any penalty.</li>
            <li><strong>Late Cancellation:</strong> If a Service Provider cancels within 3 hours of the appointment or 1 hour after the scheduled time, the admin will evaluate the situation. If the vendor is at fault, they will receive one strike. If the User is at fault and is charged a cancellation fee, the vendor will be compensated for their efforts.</li>
            <li><strong>Repeated Cancellations:</strong> If a Service Provider cancels more than three appointments within one month, their account may be blocked until resolved with the admin.</li>
          </ul>
        </ul>

        <h3>3. Refund Policy</h3>
        <p><strong>(a) Refund Eligibility:</strong></p>
        <ul>
          <li><strong>Service Appointments:</strong></li>
          <ul>
            <li><strong>User Cancellations:</strong> Users are eligible for a full refund (except platform fees) if the cancellation is made more than 3 hours before the scheduled time.</li>
            <li><strong>Provider Cancellations:</strong> Users are eligible for a full refund if a Service Provider cancels the appointment and rescheduling is not possible.</li>
            <li><strong>Service Quality Issues:</strong> If the service provided is unsatisfactory, Users may request a refund by contacting customer support within 2 days of the service. Each refund request will be evaluated individually, and refunds will be issued at the discretion of HomeLyf Services.</li>
          </ul>
          <li><strong>Subscriptions and Packages:</strong></li>
          <ul>
            <li><strong>Refund Conditions:</strong> Refunds for subscriptions or service packages are subject to the terms specified at the time of purchase. Typically, refunds are not issued for partially used or expired subscriptions or packages unless explicitly stated in the purchase terms.</li>
          </ul>
        </ul>

        <p><strong>(b) Refund Process:</strong></p>
        <ul>
          <li><strong>Request Submission:</strong> Users can submit refund requests by contacting our customer support team at services@homelyfservices.com. The request should include the reason for the refund and any relevant details or evidence.</li>
          <li><strong>Review and Approval:</strong> Refund requests will be reviewed within 3 business days. Users will be informed of the decision and any further steps required.</li>
          <li><strong>Refund Disbursement:</strong> Approved refunds will be processed within 5 business days. Refunds will be issued using the original payment method unless an alternative method is agreed upon.</li>
        </ul>

        <h3>4. Non-Refundable Services</h3>
        <p>Certain services may be designated as non-refundable. This designation will be clearly indicated at the time of purchase. Users are advised to review service details and refund eligibility before completing their purchase.</p>

        <h3>5. Platform Fees</h3>
        <p>All platform fees charged by HomeLyf Services for the use of the Platform are non-refundable under any circumstances.</p>

        <h3>6. Dispute Resolution</h3>
        <p>In the event of a disagreement regarding a cancellation or refund, Users and Service Providers are encouraged to contact HomeLyf Services customer support for assistance. HomeLyf Services will make every effort to resolve disputes amicably. If a resolution cannot be reached, the matter may be escalated according to the dispute resolution procedures outlined in our Terms and Conditions.</p>

        <h3>7. Contact Information</h3>
        <p>For any questions, concerns, or requests related to this Policy, please contact us at:</p>
        <p>
          QDTAS Technologies LLP <br />
          The Stellar Business Complex, <br />
          Near Poona Bakery, <br />
          Wakad, Pune - 411057 <br />
          info@homelyfservices.com <br />
          +91 98607 4865 / +91 93566 60761 / +91 20 4422 7377
        </p>
        <h3>8. Changes to this Policy</h3>
        <p style={{ textAlign: 'justify' }}>
        HomeLyf Services reserves the right to modify this Policy at any time. Any changes will be effective
immediately upon posting on the Platform. Your continued use of the Platform after any changes
indicates your acceptance of the modified Policy. We recommend you review this Policy periodically.
By using the Platform, you acknowledge that you have read, understood, and agree to be bound by
this Cancellation and Refund Policy
      </p>
      </div>
    </>
  );
};

export default TermsConditions;
