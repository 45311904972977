import React, { useState } from "react";
import './Contact.css';

const Contact = () => {
  const [fullName, setFullName] = useState(""); 
  const [phoneNo, setPhoneNo] = useState(""); 
  const [email, setEmail] = useState(""); 
  const [message, setMessage] = useState(""); 
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission

  // Handler functions
  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const formSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state

    // Create a form data object
    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("phoneNo", phoneNo);
    formData.append("email", email);
    formData.append("message", message);

    // Send data to Formspree
    const response = await fetch("https://usebasin.com/f/c4aa3c0b955a", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      alert(`${fullName}, thank you for getting in touch with us!`);
      // Reset the form after successful submission
      setFullName("");
      setPhoneNo("");
      setEmail("");
      setMessage("");
    } else {
      alert("There was an issue with your submission. Please try again.");
    }
    
    setIsSubmitting(false); // Reset submitting state
  };

  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Contact Us!</h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          {/* Left side: Contact Information */}
          <div className="col-md-6 col-10 mx-auto">
            <h2>Let's Talk!</h2>
            <p>
            
            </p>
            <h5>Get in Touch</h5>
            <p>
              <strong>Phone:</strong>  +91 98607 4865 / +91 93566 60761 / 020 4422 7377
            </p>
            <p>
              <strong>Email:</strong> info@homelyfservices.com
            </p>
            <p>
              <strong>Address:</strong>The Stellar, Golden Spaces Co-Working, Office 502-A,
              Hinjewadi, Near Bhumkar chowk, Next to New Poona Bakery, Pune 411057.India
              
            </p>
          </div>

          {/* Right side: Contact Form */}
          <div className="col-md-6 col-10 mx-auto">
            <form onSubmit={formSubmit}>
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="fullName"
                  value={fullName}
                  onChange={handleFullNameChange}
                  placeholder="Enter Full Name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneNo" className="form-label">
                
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNo"
                  name="phoneNo"
                  value={phoneNo}
                  onChange={handlePhoneNoChange}
                  placeholder="Enter Phone No"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
               
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  value={message}
                  onChange={handleMessageChange}
                  rows="3"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>
              <div className="col-12">
                <button className="btn btn-outline-dark" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Connecting..." : "Connect"}
                </button>
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
