import React from "react";

import { Carousel, Card, CardGroup } from "react-bootstrap";
import Common from "./Common";
import './Home.css';
import h1 from '../src/image/h1.jpg';

import Mission from "../src/image/Mission.jpg";

// Images for the carousel
import slide1 from "../src/image/9.png";
import slide2 from "../src/image/10.png";
import slide3 from "../src/image/11.png";

// Images for the cards
import cardImg1 from "../src/image/card1.jpg";
import cardImg2 from "../src/image/card2.jpg";
import cardImg3 from "../src/image/card3.jpg";
import cardImg4 from "../src/image/card4.jpg";

const Home = () => {
  return (
    <>
    
      {/* Carousel Section */}
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide2}
            alt="First slide"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide3}
            alt="Second slide"
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>

      
      </Carousel>
     

  {/*
   <div className="text-center mt-5">
        <h2 className="section-heading">Our Services</h2>
      </div>
    Cards Section
    <div className="container mt-4">
        <div className="row">

            Plumbing Card
            <div className="col-12 col-sm-6 col-md-3 mb-3">
                <Card className="h-100 mx-2">
                    <Card.Img variant="top" src={cardImg1} />
                    <Card.Body>
                        <Card.Title>Plumbing</Card.Title>
                        <Card.Text>
                            From leaky faucets to major plumbing repairs, our experts can handle it all.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            Electrical Card
            <div className="col-12 col-sm-6 col-md-3 mb-3">
                <Card className="h-100 mx-2">
                    <Card.Img variant="top" src={cardImg2} />
                    <Card.Body>
                        <Card.Title>Electrical</Card.Title>
                        <Card.Text>
                            We provide safe and reliable electrical services, including installations and repairs.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            Home Cleaning Card
            <div className="col-12 col-sm-6 col-md-3 mb-3">
                <Card className="h-100 mx-2">
                    <Card.Img variant="top" src={cardImg3} />
                    <Card.Body>
                        <Card.Title>Home Cleaning</Card.Title>
                        <Card.Text>
                            Our professional cleaners will keep your home spotless and hygienic.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            Landscaping Card
            <div className="col-12 col-sm-6 col-md-3 mb-3">
                <Card className="h-100 mx-2">
                    <Card.Img variant="top" src={cardImg4} />
                    <Card.Body>
                        <Card.Title>Landscaping</Card.Title>
                        <Card.Text>
                            Enhance your home's curb appeal with our expert landscaping services.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

        </div>
    </div>
*/}

      <div className="text-center mt-5">
  <h2 className="section-heading"></h2>
</div>

{/* Mission Statement Section */}
<div className="container mission-section">
  <div className="mission-image">
    <img src={Mission}  alt="Mission Image" />
  </div>
  <div className="mission-statement">
  <div className="why-container why-flex">
        <div className="why-text">
          <h3 className="why-title">Our Mission</h3>
    <p>
      Our mission is to provide high-quality home services that make your life easier and your home better. 
      We are committed to delivering excellent service with a personal touch, ensuring your complete satisfaction.
    </p>
  </div>
</div>
</div>
</div>

     
      <div className="why-section" id="why-choose-us">
      <div className="why-container why-flex">
        <div className="why-text">
          <h3 className="why-title">Why Choose Us?</h3>
          <h3 className="why-subtitle">Expert Consultation.</h3>
          <p className="why-description">
          We are dedicated to providing exceptional service and customer satisfaction.
          </p>

          <h3 className="why-subtitle"> Here are a few reasons to choose HomeLyf Services:</h3>
          <p className="why-description">
          <ul>
        <li>Experienced and skilled professionals</li>
        <li>Competitive pricing</li>
        <li>Timely and reliable service</li>
        <li>Personalized solutions tailored to your needs</li>
      </ul>
          </p>
        </div>
        <div className="why-visual">
       
          <img src={h1} 
            alt="Why Choose Us"
            className="why-image"
          />
        </div>
      </div>
    </div>
      {/* Existing Common Component Section */}
      {/* 
<Common 
    name=""
    name1=""
    imgsrc={web} 
    visit="/Service"
    btname="Get Started"
/> 
*/}

    </>
  );
};

export default Home;
