import React, { useState } from "react";
import Card from "./card"; // Make sure to use uppercase "Card" here
import Sdata from "./sdata";
import './Service.css';

const Service = () => {
  const [showAll, setShowAll] = useState(false); // State to control visibility

  const handleReadMore = () => {
    setShowAll(!showAll); // Toggle the state
  };

  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Our Services</h1>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
              {Sdata.slice(0, showAll ? Sdata.length : 4).map((val, ind) => (
                <Card
                  key={ind}
                  imgsrc={val.imgsrc}
                  title={val.title}
                  des={val.Des}
                  btnname={val.btnname}
                />
              ))}
            </div>
            <div className="text-center mt-4">
              <button className="btn btn-dark" onClick={handleReadMore}>
                {showAll ? "Show Less" : "Read More"} 
                <i className={`fas fa-chevron-down ml-2 ${showAll ? 'rotate' : ''}`}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
