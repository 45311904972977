import React from "react";
import { Nav, Navbar } from "react-bootstrap"; // Import Nav and Navbar if you need them
import playstore from '../src/image/playstore.png';
import appstore from '../src/image/appstore.png';
import mobilemockup from '../src/image/mobilemockup.png';
import './Download.css';


const Download = () => {
  return (
    <>
    <div className="my-5">
        <h1 className="text-center">Download App !</h1>
      </div>
         <div className="app">
      <div className="content">
        <h1>Helping Dreamers Become Doers</h1>
        <p>
        "HomeLyf Services is dedicated to providing a diverse array of home services tailored to meet your unique needs. Our team of professionals is committed to delivering exceptional quality with a personal touch."
        </p>
        <div className="download-buttons">
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <img src={playstore} alt="Get it on Google Play" />
          </a>
          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={appstore} alt="Download on the App Store" />
           
          </a>
        </div>
      </div>

      <div className="mockup">
      
        <img src={mobilemockup} alt="Mobile Mockup" />
      </div>
    </div>

    </>
  );
};

export default Download;
