import web from"../src/image/card1.jpg";
import app from"../src/image/card2.jpg";

import digital from"../src/image/card3.jpg";

import Painting from"../src/image/Painting.jpg";
import Electronics from"../src/image/Electronics.jpg";
import Electricals from"../src/image/Electricals.jpg";
import Carpentry from"../src/image/Carpentry.jpg";
import Waterproofing from"../src/image/Waterproofing.jpg";
import PestControl from"../src/image/PestControl.jpg";

import WaterPurifier from"../src/image/WaterPurifier.jpg";
import Vehicle from"../src/image/Vehicle.jpg";
import SpaWomen from"../src/image/SpaWomen.jpg";
import SpaMen from"../src/image/SpaMen.jpg";
import Salonmen from"../src/image/Salonmen.jpg";
import SalonWomen from"../src/image/SalonWomen.jpg";
import software from"../src/image/card4.jpg";

const Sdata=[
    {
        imgsrc:Painting,
        title:"Painting",
        Des:"Transform your home with a fresh coat of paint, enhancing both aesthetics and value.",
        btnname:"Contact Us",
    },
    {
        imgsrc:web,
        title:"Plumbing",
        Des:"From minor leaks to major repairs, our plumbing experts have got you covered.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Electronics,
        title:"Electronics",
        Des:"We offer reliable electrical installations and repairs, ensuring the safety of your home.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Carpentry,
        title:"Carpentry",
        Des:"Our skilled carpenters provide custom solutions to meet all your woodworking needs.",
        btnname:"Contact Us",
    },
    {
        imgsrc:digital,
        title:"Cleaning",
        Des:"Keep your home spotless with our professional cleaning services tailored for hygiene and comfort.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Electricals,
        title:"Electricals",
        Des:"Ensure safe and efficient electrical systems with our expert installation and repair services.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Waterproofing,
        title:"Waterproofing",
        Des:"Protect your home from water damage with our top-tier waterproofing solutions.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Vehicle,
        title:"Vehicle",
        Des:"Comprehensive vehicle services, from routine maintenance to repairs, to keep your ride smooth.",
        btnname:"Contact Us",
    },
    {
        imgsrc:WaterPurifier,
        title:"Water Purifier",
        Des:"Ensure safe drinking water with our reliable water purifier installation and maintenance.",
        btnname:"Contact Us",
    },

    {
        imgsrc:PestControl,
        title:"Pest Control",
        Des:"Say goodbye to unwanted pests with our effective pest control solutions.",
        btnname:"Contact Us",
    },
    {
        imgsrc:SalonWomen,
        title:"Salon Women",
        Des:"Pamper yourself with our personalized salon services, tailored specifically for women.",
        btnname:"Contact Us",
    },
    {
        imgsrc:Salonmen,
        title:"Salon Men",
        Des:"Experience top-notch grooming services designed to keep men looking sharp.",
        btnname:"Contact Us",
    },{
        imgsrc:SpaWomen,
        title:"Spa Women",
        Des:"Relax and rejuvenate with our luxurious spa treatments designed for women.",
        btnname:"Contact Us",
    },{
        imgsrc:SpaMen,
        title:"Spa Men",
        Des:"Unwind with our specially curated spa services designed for men's unique needs.",
        btnname:"Contact Us",
    },
    
   
    
]
export default Sdata;