import React from "react";
// Import your CSS file for additional styling
import { Link } from 'react-router-dom';
import './footer.css';
import { Nav, Navbar } from "react-bootstrap";
const Footer = () => {
    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Services</h3>
                            <ul>
                                <li><a href="#">Plumbing</a></li>
                                <li><a href="#">Electrical</a></li>
                                <li><a href="#">Home Cleaning</a></li>
                                <li><a href="#">Landscaping</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 item">
                            <h3>About</h3>
                            <ul>
                                <li><a href="https://www.qdtas.com/index.html">Company</a></li>
                                <li><a href="#">Team</a></li>
                                <li><a href="#">Careers</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 item text">
                       
                            <h3>HomeLyf Service</h3>
                            <p>
                            
                           <p></p> 
                           <p> Phone: +91 98607 4865 / +91 93566 60761 / 020 4422 7377</p>
                            <p>Email: info@homelyfservices.com</p>
                            <p>Address:The Stellar, Golden Spaces Co-Working, Office 502-A,
                            Hinjewadi, Near Bhumkar chowk, Next to New Poona Bakery, Pune 411057.India</p>
                           
                            </p>
                        </div>
                        <div className="col item social">
                            <a href="#"><i className="icon ion-social-facebook"></i></a>
                            <a href="#"><i className="icon ion-social-twitter"></i></a>
                            <a href="#"><i className="icon ion-social-snapchat"></i></a>
                            <a href="#"><i className="icon ion-social-instagram"></i></a>
                        </div>
                    </div>
                    <div className="footer-bottom">
    <p>
        &copy; 2024 Homelyf Services. All Rights Reserved. 
        &nbsp; &nbsp; {/* Adding space and separator */}
        <Nav.Link as={Link} to="/TermsConditions" activeClassName="menu_active"  style={{ display: 'inline' }}>
        Terms & Conditions for customers
        </Nav.Link>
        <Nav.Link as={Link} to="/TermsConditionsPartners" activeClassName="menu_active"  style={{ display: 'inline' }}>
        Terms & Conditions for Partners
        </Nav.Link>
       {/* Adding space and separator */}
        <Nav.Link as={Link} to="/PrivacyPolicy" activeClassName="menu_active"  style={{ display: 'inline' }}>
            Privacy Policy
        </Nav.Link>
        <Nav.Link as={Link} to="/CancellationRefundPolicy" activeClassName="menu_active" style={{ display: 'inline' }}>
           Cancellation and Refund Policy
        </Nav.Link>
    </p>
</div>

                </div>
            </footer>
        </div>
    );
}

export default Footer;
