import React from "react";
import web from "../src/image/img3.png";
import man from "../src/image/About.jpg";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common from "./Common";
import './About.css'; // Create a CSS file for custom styling if needed

const About = () => {
  return (
    <>
      {/* 
<Common 
  name="WELCOME TO ABOUT PAGE"
  name1="Our Vision Craft disruptive solutions that steer the way to efficient business"
  imgsrc={web} 
  visit="/contact"
  btname="Contact Now"
/> 
*/}

<div className="my-5">
        <h1 className="text-center">About Us!</h1>
      </div>
      {/* Adding the section for future goals */}
      <section className="about-section">
        <div className="container">
          <div className="row">                
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About HomeLyf Services</span>
                  <h3>Your Trusted Home Service Provider</h3>
                </div>
                <div className="text">HomeLyf Services is dedicated to offering a wide range of home services to meet your needs. Our team of professionals is committed to providing high-quality service with a personal touch.</div>
                <div className="text">
                HomeLyf Services is a trusted provider of comprehensive home maintenance and improvement solutions. We specialize in a wide range of services, including plumbing, electrical work, home cleaning, and landscaping. With a team of skilled professionals, we ensure quality and reliability in every job, offering peace of mind to our customers. Our commitment to customer satisfaction drives us to deliver timely and efficient services tailored to meet individual needs. At HomeLyf, we take pride in making homes safer, cleaner, and more beautiful, creating comfortable living spaces for our clients. Your home, our priority.
                </div>
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn btn-style-one">Contact Us</Link> {/* Button to contact us */}
                </div>
              </div>
            </div>

            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                
                <figure className="image-1">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                  <img src={man} alt="Download on the App Store" />
                   
                  </a>
                </figure>
              </div>
            </div>
          </div>
          
          <div className="sec-title">
            <span className="title">Our Future Goals at HomeLyf Services</span>
            <h3>Leading the Way in Home Solutions and Technology</h3>
          </div>
          <div className="text">
          HomeLyf Services is dedicated to pioneering innovation in home maintenance, blending technology with craftsmanship to elevate quality and aesthetics.
          </div>
          <div className="text">
          We offer tailored solutions that not only meet functional needs but also enhance the visual appeal of your home, ensuring exceptional performance and design.
          </div>
          <div className="text">                
          Committed to empowering our customers, we provide free access to valuable resources and technical content to aid in informed decision-making.
          </div>
          <div className="text">
          We foster a collaborative community by encouraging customer feedback, showcasing valuable insights, and inviting regular visits to our platform for premium content and solutions.
          </div>
          <div className="text">
            In the end, I would say keep visiting our website and enjoy the quality content.
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
